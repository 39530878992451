import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";

const Navbar = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(graphql`
    query SitemapQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              path
              title
              category
              template
            }
            tableOfContents
          }
        }
      }
    }
  `);
  const nodes = edges.map(e => e.node);
  const filterNodes = (path: string) => {
    return _.sortBy(
      nodes.filter(n => n.fileAbsolutePath.indexOf(`/content/${path}/`) != -1),
      "fileAbsolutePath"
    );
  };
  const nodeByPath = (path: string) => {
    return nodes.find(n => n.frontmatter.path == path);
  };
  const MENU = [
    { title: "Accueil", link: "/" },
    { title: "Assurance décennale", links: filterNodes("assurance-decennale") },
    {
      title: "Votre métier",
      links: filterNodes("votre-metier").filter(
        n => n.frontmatter.template == "listing"
      ),
    },
    {
      title: "Statut d'entreprise",
      links: filterNodes("status-entreprise"),
    },
    { title: "Dommages-ouvrage", links: filterNodes("dommages-ouvrage") },
    {
      title: "Devis",
      links: [
        nodeByPath("/prix"),
        { frontmatter: { path: "/devis", title: "Votre devis" } },
      ]
        .concat(
          filterNodes("devis").filter(n => n.frontmatter.template == "listing")
        )
        .filter(Boolean),
    },
    { title: "Blog", link: "/blog" },
  ].filter(node => {
    if (node.link) return true;
    return node.links && node.links.length > 0;
  });

  return (
    <div className="sticky-top bg-white">
      <nav className="navbar navbar-expand-lg navbar-light  border-bottom  py-0 py-md-2">
        <div className="container">
          <div className="d-flex">
            <a
              className="d-none d-md-block nav-link text-primary"
              href="/dictionnaire-assurance-decennale"
            >
              Dictionnaire décennale
            </a>
            <a
              className="d-none d-md-block nav-link text-primary"
              href="/faq-rc-decennale"
            >
              FAQ RC décennale
            </a>
            <a className="nav-link text-primary" href="/contact">
              Contact
            </a>
          </div>
          <a className="nav-link text-primary font-weight-bold" href="/devis">
            DEMANDE DE DEVIS
          </a>
        </div>
      </nav>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a href="/" className="navbar-brand">
            <img src="/logo.png" height="40" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobileMenu"
            aria-controls="mobileMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div id="mobileMenu" className="collapse navbar-collapse">
            <div
              className="d-block d-lg-none py-4 px-2"
              style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}
            >
              {MENU.map((item, i) => {
                if (item.link) {
                  return (
                    <a
                      key={i}
                      className="d-block py-1 h5 mb-3"
                      href={item.link}
                    >
                      {item.title}
                    </a>
                  );
                }
                return (
                  <div key={i}>
                    <h5>{item.title}</h5>
                    <ul className="list-unstyled">
                      {item.links.map((node, i) => (
                        <li key={i}>
                          <a
                            className="d-block py-1"
                            href={node.frontmatter.path}
                          >
                            {node.frontmatter.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            id="desktopMenu"
            className="collapse navbar-collapse justify-content-end pr-4"
          >
            {MENU.map((item, i) => {
              if (item.link) {
                return (
                  <a className="nav-link" href={item.link} key={i}>
                    {item.title}
                  </a>
                );
              }
              return (
                <div className="dropdown" key={i}>
                  <a
                    href="#"
                    className="nav-link"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {item.title}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {item.links.map((node, i) => (
                      <a
                        key={i}
                        className="dropdown-item"
                        href={node.frontmatter.path}
                      >
                        {node.frontmatter.title}
                      </a>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </nav>
      <style>
        {`
        #desktopMenu
          a.nav-link {
            font-size: 14px !important;          
        }`}
      </style>
    </div>
  );
};

export default Navbar;
